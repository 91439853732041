import countries from "i18n-iso-countries"
import enLocale from "i18n-iso-countries/langs/en.json"

export const displayMessage = (message, setMessage) => {
  setMessage(message)

  setTimeout(() => {
    setMessage()
  }, 3000)
}

export const getCountryList = () => {
  countries.registerLocale(enLocale)
  const countryObj = countries.getNames("en")
  return Object.entries(countryObj).map(([key, value]) => {
    return { code: key, name: value }
  })
}

export class UserInfoError extends Error {
  constructor(msg) {
    super(msg)
  }
}

export const currencyFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
})
