import PropTypes from "prop-types"
import React from "react"
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { Link } from "react-router-dom"
import logoLightPng from "../../assets/images/WNA-logo.png"
import logoLightSvg from "../../assets/images/WNA-logo.png"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="20" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="80" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

export default withTranslation()(Sidebar)
