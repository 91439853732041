import React from "react"
import { Label } from "reactstrap"

const Question = ({
  question,
  fieldName,
  answers,
  errors,
  onChange,
  multi = false,
  questionNumber = 0,
}) => {
  return (
    <div className="mb-5">
      <Label className="form-label mb-3">
        {questionNumber}. {question.text}
      </Label>

      {answers.map((answer, index) => (
        <p key={index}>
          <input
            id={`q${question.id}-${index}`}
            type={multi ? "checkbox" : "radio"}
            style={{ cursor: "pointer" }}
            name={fieldName}
            value={"" + index}
            onChange={e =>
              onChange(
                question.id,
                { text: answer.text, points: answer.points },
                e,
                multi
              )
            }
          />
          <label
            className="ms-2 mb-0 text-muted"
            style={{ cursor: "pointer" }}
            htmlFor={`q${question.id}-${index}`}
          >
            {answer.text} ({answer.points} Punkte)
          </label>
        </p>
      ))}
      {errors.includes(question.id) && (
        <div className="text-danger">Bitte auswählen!</div>
      )}
    </div>
  )
}

export default Question
