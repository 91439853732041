import { Field } from "formik"
import React from "react"
import { Input, Label } from "reactstrap"

const FormikInputField = ({
  fieldName,
  label,
  fieldType = "text",
  touched,
  errors,
  disabled = false,
  className,
}) => {
  return (
    <div className={"mb-3 " + className}>
      <Label className="form-label">{label}</Label>
      <Field
        name={fieldName}
        type={fieldType}
        as={CustomInput}
        invalid={errors[fieldName] && touched[fieldName]}
        disabled={disabled}
      />
      {errors[fieldName] && touched[fieldName] && (
        <div className="text-danger">{errors[fieldName]}</div>
      )}
    </div>
  )
}

const CustomInput = props => <Input className="form-control" {...props} />

export default FormikInputField
