import React, { useEffect } from "react"
import withRouter from "components/Common/withRouter"
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

const Layout = props => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const dataLayoutMode = localStorage.getItem("data-layout-mode")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    document.body.setAttribute("data-layout-mode", dataLayoutMode)
    document.body.setAttribute("data-topbar", dataLayoutMode)
  }, [dataLayoutMode])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar isMobile={isMobile} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(Layout)
