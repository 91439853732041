import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Table,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import {
  AcceptClassifications,
  DeleteClassifications,
  GetClassifications,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import AcceptModal from "components/Common/AcceptModal"
import moment from "moment"

const Classifications = props => {
  const [selectedClassification, setSelectedClassification] = useState()
  const [classifications, setClassifications] = useState([])
  const [acceptModal, setAcceptModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const setLoading = useLoading()

  const onClickDelete = classifications => {
    setSelectedClassification(classifications)
    setDeleteModal(true)
  }

  const onClickAccept = classifications => {
    setSelectedClassification(classifications)
    setAcceptModal(true)
  }

  const handleDelete = async () => {
    if (selectedClassification && selectedClassification.id)
      await DeleteClassifications(selectedClassification.id)
    loadData()
    setDeleteModal(false)
  }

  const handleAcceptation = async () => {
    setLoading(true)
    if (selectedClassification && selectedClassification.id)
      await AcceptClassifications(selectedClassification.id)
    loadData()
    setAcceptModal(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    let classifications = await GetClassifications()

    classifications = classifications.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    )

    setClassifications(classifications)
    setSelectedClassification()
    setLoading(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        displayMessage={
          "Sind Sie sicher, dass Sie diese Klassifizierung löschen wollen?"
        }
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AcceptModal
        show={acceptModal}
        displayMessage={
          "Sind Sie sicher, dass Sie diese Klassifizierung bestätigen wollen?"
        }
        onAcceptClick={handleAcceptation}
        onCloseClick={() => setAcceptModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Klassifizierungen" breadcrumbItem="Übersicht" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Salon</th>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>Ort</th>
                        <th>Land</th>
                        <th>Email</th>
                        <th>Tel</th>
                        <th>Punkte</th>
                        <th>Datum</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {classifications.length === 0 && (
                        <tr>
                          <td colSpan={5} className="text-center">
                            Es gibt derzeit keine Klassifizierungen.
                          </td>
                        </tr>
                      )}
                      {classifications.map((classification, index) => (
                        <tr key={index}>
                          <td>
                            <div className="avatar-xs">
                              <span className="avatar-title rounded-circle">
                                {classification.firstname.charAt(0)}
                              </span>
                            </div>
                          </td>
                          <td className="align-middle">
                            {classification.name}
                          </td>
                          <td className="align-middle">
                            {classification.firstname}
                          </td>
                          <td className="align-middle">
                            {classification.lastname}
                          </td>
                          <td className="align-middle">
                            {classification.city}
                          </td>
                          <td className="align-middle">
                            {classification.country}
                          </td>
                          <td className="align-middle">
                            {classification.email}
                          </td>
                          <td className="align-middle">
                            {classification.phoneNr}
                          </td>
                          <td className="align-middle">
                            {classification.points}
                          </td>
                          <td className="align-middle">
                            {moment(classification.date).format("DD.MM.YYYY")}
                          </td>
                          <td className="align-middle">
                            {!classification.status && (
                              <div className="d-flex gap-3">
                                <Link
                                  to="#"
                                  className="text-success"
                                  onClick={() => {
                                    onClickAccept(classification)
                                  }}
                                >
                                  <i
                                    className="mdi mdi-check font-size-18"
                                    id="edittooltip"
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="edittooltip"
                                  >
                                    Akzeptieren
                                  </UncontrolledTooltip>
                                </Link>
                                <Link
                                  to="#"
                                  className="text-danger"
                                  onClick={() => {
                                    onClickDelete(classification)
                                  }}
                                >
                                  <i
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="deletetooltip"
                                  >
                                    Löschen
                                  </UncontrolledTooltip>
                                </Link>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Classifications)
