import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"

//apply base url for axios
const API_URL = process.env.REACT_APP_API_BASE_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers["apiKey"] = process.env.REACT_APP_API_KEY

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  try {
    return await axiosApi
      .get(url, { ...config })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

export async function post(url, data, config = {}) {
  try {
    return await axiosApi
      .post(url, data, { ...config })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

export async function put(url, data, config = {}) {
  try {
    return await axiosApi
      .put(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

export async function del(url, config = {}) {
  try {
    return await axiosApi
      .delete(url, { ...config })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

const handleError = error => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("authUser")
    window.location.href = "/login"
  } else throw error
}
