import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import classNames from "classnames"
import ConfirmationTab from "./ConfirmationTab"
import QuestionTab from "./QuestionTab"
import SalonInfoTab from "./SalonInfoTab"
import { CreateClassification } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import MemberTab from "./MemberTab"
import { displayMessage } from "helpers/misc_helpers"

const Classification = props => {
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [salon, setSalon] = useState({
    name: "JUST4ONLINE",
    salutation: "Herr",
    firstname: "Marcel",
    lastname: "Judth",
    street: "Kärntner Straße 7",
    email: "web@just4online.com",
    phoneNr: "+4367682595032",
    postalCode: "9220",
    city: "Velden am wörthersee",
    country: "AT",
  })
  const [answers, setAnswers] = useState([])
  const [error, setError] = useState()
  const setLoading = useLoading()
  const navLinks = ["Kontakdaten", "Fragebogen", "Mitgliedschaft", "Abschluss"]

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]

      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleAnswerChange = (questionIndex, answer, event, isMulti) => {
    if (event.currentTarget.checked) {
      let tmp = answers
      if (!isMulti) tmp = tmp.filter(a => a.questionIndex !== questionIndex)
      tmp.push({ questionIndex, answer })
      setAnswers(tmp)
    } else {
      setAnswers(
        answers.filter(
          a =>
            !(a.questionIndex == questionIndex && a.answer.text == answer.text)
        )
      )
    }
  }

  const handleSubmit = async _ => {
    try {
      setLoading(true)
      let points = 0
      answers.forEach(question => (points += question.answer.points))

      await CreateClassification({
        ...salon,
        points,
      })

      toggleTab(activeTab + 1)
      setLoading(false)
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <a href="https://world-nail-association.com" className="text-dark">
          <i className="bx bx-home h2" />
        </a>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <Card className="overflow-hidden">
                <CardBody className="">
                  <h4 className="card-title mb-4">Klassifizierung</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        {navLinks.map((navlink, index) => (
                          <NavItem
                            key={index}
                            className={classNames({
                              current: activeTab === index + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === index + 1,
                              })}
                              onClick={() => {
                                setactiveTab(index + 1)
                              }}
                              disabled={
                                !(passedSteps || []).includes(index + 1)
                              }
                            >
                              <span className="number">{index + 1}.</span>{" "}
                              {navlink}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <SalonInfoTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                          setSalon={setSalon}
                        />
                        <QuestionTab
                          heading="Salon"
                          tabNumber={2}
                          nextTab={handleSubmit}
                          answers={answers}
                          handleAnswerChange={handleAnswerChange}
                        />

                        <MemberTab
                          salon={salon}
                          nextTab={_ => toggleTab(activeTab + 1)}
                        />

                        <ConfirmationTab />
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Classification
