export const LOGIN = "/users/login"

export const REGISTER = "/users/register"

export const CONFIRM_EMAIL = "/users/confirm-email"

export const USERS = "/users"

export const RESET_PASSWORD = "/users/resetPassword"

export const CHANGE_EMAIL = "/users/change-email"

export const CHANGE_EMAIL_CONFIRMATION = "/users/change-email-confirmation"

export const MY_USER = "/users/myAccount"

export const CLASSIFICATIONS = "/classifications"

export const INVOICES = "/invoices/create"
