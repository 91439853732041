import React from "react"

import { Col, Input, Label, Row, TabPane } from "reactstrap"
import { Field, Formik, Form } from "formik"
import FormikInputField from "../../helpers/FormikInputField"
import * as Yup from "yup"
import { getCountryList } from "helpers/misc_helpers"
import wnaLogo from "../../assets/images/WNA-logo.png"

const SalonInfoTab = ({ nextTab, setSalon }) => {
  const countryList = getCountryList()

  const initialValues = {
    name: "",
    salutation: "",
    firstname: "",
    lastname: "",
    street: "",
    email: "",
    phoneNr: "",
    postalCode: "",
    city: "",
    country: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    salutation: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    firstname: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    lastname: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    email: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    phoneNr: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    street: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    postalCode: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    city: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    phoneNr: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
    country: Yup.string().required("Dieses Feld ist ein Pflichtfeld"),
  })

  var fields = [
    {
      fieldName: "firstname",
      label: "Vorname",
    },
    {
      fieldName: "lastname",
      label: "Nachname",
    },
    {
      fieldName: "street",
      label: "Straße",
    },
    {
      fieldName: "postalCode",
      label: "Postleitzahl",
    },
    {
      fieldName: "city",
      label: "Ort/Stadt",
    },
  ]

  const handleSubmit = async values => {
    setSalon(values)
    nextTab()
  }

  return (
    <TabPane tabId={1}>
      <Row className="d-flex justify-content-center mb-5">
        <Col lg="6">
          <div className="text-center">
            <div className="mb-4">
              <img src={wnaLogo} />
            </div>
            <div>
              <h5>FRAGEBOGEN ZUR STERNE KLASSIFIZIERUNG!</h5>
              <p className="text-muted">
                Das Klassifizierungssystem garantiert dem Kunden Qualität und
                Service, Transparenz und Sicherheit.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Row>
                <Col lg="6">
                  <FormikInputField
                    fieldName="name"
                    label="Salon Name"
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-cstno-input7">Anrede:</Label>
                    <Field
                      as="select"
                      className="form-select"
                      name="salutation"
                    >
                      <option disabled value="">
                        -
                      </option>
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Keine Angabe">Keine Angabe</option>
                    </Field>
                    {errors.salutation && touched.salutation && (
                      <div className="text-danger">{errors.salutation}</div>
                    )}
                  </div>
                </Col>
                {fields.map((field, key) => (
                  <Col lg="6" key={key}>
                    <FormikInputField
                      fieldName={field.fieldName}
                      label={field.label}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                ))}
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-cstno-input7">Land</Label>
                    <Field as="select" className="form-select" name="country">
                      <option disabled value="">
                        -
                      </option>

                      {countryList.map((value, key) => (
                        <option key={key} value={value.code}>
                          {value.name}
                        </option>
                      ))}
                    </Field>
                    {errors.country && touched.country && (
                      <div className="text-danger">{errors.country}</div>
                    )}
                  </div>
                </Col>

                <Col lg="6">
                  <FormikInputField
                    fieldName="phoneNr"
                    label="Telefonnummer"
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col lg="6">
                  <FormikInputField
                    fieldName="email"
                    label="E-Mail"
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <div className="form-check mt-3">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="dsgvo"
                      required
                    />
                    <label htmlFor="dsgvo" className="form-check-label">
                      Ich habe die{" "}
                      <a
                        href="https://world-nail-association.com/datenschutz/"
                        className="text-primary"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Datenschutzrichlinien
                      </a>{" "}
                      gelesen
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <p className="mt-4">
                  (Der Salonbesitzer verpflichtet sich eidesstattlich, seine
                  Angaben wahrheitsgetreu zu machen. Im Falle einer Änderung ist
                  die World Nail Association unter{" "}
                  <a href="mailto:office@world-nail-association.com">
                    office@world-nail-association.com
                  </a>{" "}
                  zu informieren. <br />
                  <br />
                  Der Salonbesitzer stimmt auch einer Überprüfung seiner Angaben
                  durch autorisierte Kontrolleure der WNA ausdrücklich zu. Der
                  Salonbesitzer erklärt sich einverstanden, dass im Zuge des
                  Antrages der Einstufung der Klassifizierung seine Angaben im
                  Erhebungs- bogen verarbeitet werden. Die Zustimmung zur
                  Datenverarbeitung kann jederzeit wider- rufen werden).
                </p>
              </Row>
              <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Weiter
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </TabPane>
  )
}

export default SalonInfoTab
