import React from "react"

import { Col, TabPane } from "reactstrap"
import wnaLogo from "../../assets/images/WNA-logo.png"

const ConfirmationTab = props => {
  return (
    <TabPane tabId={4}>
      <div className="row justify-content-center">
        <Col lg="6">
          <div className="text-center">
            <div className="mb-4">
              <img src={wnaLogo} />
            </div>
            <div>
              <h5>Vielen Dank für Ihre Klassifizierung!</h5>
              <p className="text-muted">
                {props.isMonthly
                  ? "Nach erfolgreicher Überprüfung Ihrer Klassifizierung, erhalten Sie die Urkunde per E-Mail."
                  : "Sie erhalten innerhalb der nächsten 24h eine Rechnung für den Mitgliedsbeitrag der World Nail Association. Nach Erhalt des vollständigen Rechnungbetrages, erhalten Sie Ihre Klassifizierung per E-Mail. "}
              </p>
              <a
                href="https://world-nail-association.com"
                className="btn btn-primary"
              >
                Zurück zur Webseite
              </a>
            </div>
          </div>
        </Col>
      </div>
    </TabPane>
  )
}

export default ConfirmationTab
