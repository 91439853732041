import React, { useState } from "react"

import { Card, CardBody, Col, Row, TabPane } from "reactstrap"
import wnaLogo from "../../assets/images/WNA-logo.png"
import { CreateInvoice } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"

const MemberTab = ({ nextTab, salon }) => {
  const setLoading = useLoading()

  async function handleInvoiceCreation() {
    try {
      setLoading(true)
      const reqObj = {
        name: salon.name,
        street: salon.street,
        postalCode: salon.postalCode,
        city: salon.city,
        country: salon.country,
        email: salon.email,
      }
      const result = await CreateInvoice(reqObj)
      console.log(result)
      nextTab()
    } catch (error) {
      alert("An unexpected error happened!")
      console.log(error)
    }

    setLoading(false)
  }

  return (
    <TabPane tabId={3}>
      <div className="row justify-content-center">
        <div className="text-center">
          <div className="mb-4">
            <img src={wnaLogo} />
          </div>
          <div>
            <h5>Bearbeitungsgebühren WNA-Klassifizierung</h5>

            <p
              className="mt-3"
              style={{
                maxWidth: "500px",
                margin: "auto",
                marginBottom: "40px",
              }}
            >
              <br />
              Für diese Klassifizierung fallen Bearbeitungsgebühren an. Sie
              erhalten nach Abschluss der Zertifizierung eine Rechnung per
              E-Mail. Nach vollständiger Bezahlung erhalten Sie die
              Klassifizierung ebenfalls per E-Mail.
            </p>

            <Row className="justify-content-md-center">
              <Col xl="4">
                <Card className="plan-box border border-2">
                  <CardBody className="p-4">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h5 className="text-center">Gebühren</h5>
                        <p className="text-muted text-center pt-2">
                          Die Klassifizierung ist 1 Jahr gültig.
                        </p>
                      </div>
                    </div>
                    <div className="py-2">
                      <h2 className="text-center" style={{ fontSize: "30px" }}>
                        € 114,00
                      </h2>
                      <span className="text-center text-muted d-block mb-2">
                        inkl. 20% Mwst.
                      </span>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-outline-primary mb-2 w-100"
                        onClick={handleInvoiceCreation}
                      >
                        Jetzt kostenpflichtig bestellen
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </TabPane>
  )
}

export default MemberTab
