import React, { useState } from "react"

import { Col, Row, TabPane } from "reactstrap"
import Question from "pages/Classification/Question"
import {
  EducationQuestions,
  HygieneQuestions,
  MediaQuestions,
  MembershipQuestions,
  SalonQuestions,
  SanitaryQuestions,
  SecurityQuestions,
  ServiceQuestions,
} from "../../assets/data/questions"

const QuestionTab = ({ nextTab, answers, tabNumber, handleAnswerChange }) => {
  const [errors, setErrors] = useState([])
  const allQuestions = [
    ...EducationQuestions,
    ...HygieneQuestions,
    ...MediaQuestions,
    ...MembershipQuestions,
    ...SalonQuestions,
    ...SanitaryQuestions,
    ...SecurityQuestions,
    ...ServiceQuestions,
  ]
  let questionIndex = 0

  const handleSubmit = () => {
    const err = []

    for (let i = 0; i < allQuestions.length; i++)
      if (!answers.some(a => a.questionIndex == i)) err.push(i)
    console.log(err)
    setErrors(err)
    if (err.length == 0) nextTab()
  }

  return (
    <TabPane tabId={tabNumber}>
      <div>
        <h3 className="mb-3">Salon</h3>
        <Row>
          {SalonQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <h3 className="mb-3 mt-3">Service</h3>
        <Row>
          {ServiceQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <h3 className="mb-3 mt-3">Medien & Lage</h3>
        <Row>
          {MediaQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <h3 className="mb-3 mt-3">Hygiene</h3>
        <Row>
          {HygieneQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <h3 className="mb-3 mt-3">Sanitärbereich</h3>
        <Row>
          {SanitaryQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <h3 className="mb-3 mt-3">Ausbildung</h3>
        <Row>
          {EducationQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <h3 className="mb-3 mt-3">Sicherheit</h3>
        <Row>
          {SecurityQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <h3 className="mb-3 mt-3">Mitgliedschaften</h3>
        <Row>
          {MembershipQuestions.map((question, index) => (
            <Col lg="6" key={index}>
              <Question
                question={{ ...question, id: questionIndex++ }}
                answers={question.answers}
                fieldName={questionIndex}
                errors={errors}
                onChange={handleAnswerChange}
                questionNumber={questionIndex}
                multi={question.multi}
              />
            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-end">
          {errors.length > 0 && (
            <p className="text-danger me-2">Bitte alle Felder ausfüllen.</p>
          )}
          <button
            type="button"
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Absenden
          </button>
        </div>
      </div>
    </TabPane>
  )
}

export default QuestionTab
