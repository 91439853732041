export const SalonQuestions = [
  {
    text: "Ist Ihr Salon von außen gut erkennbar?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Haben Sie Parkplätze vor dem Geschäft?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verfügen Sie über ein Schaufenster?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verfügen Sie über einen Warteraum bzw. Warteecke?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Ist die Sitzgelegenheit bequem (gepolsterte Sessel / Couch mit Beistelltisch /Ablage)?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin eine Garderobe mit einer angemessenen Anzahl von Kleiderbügel und Kleiderhaken an?",
    answers: [
      {
        text: "Ja",
        points: 1,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verfügt Ihr Salon über einen „Baby Wickeltisch“?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie eine „Kleinkindbetreuung“ während der Behandlung durch geschultes Personal an?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Gibt es einen sicheren „Kids Corner“ mit Spielsachen?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Gibt es eine „Doggy‘s Snack Bar“ (Hundefutter und Wasser)?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Haben Sie einen „Empfangsbereich“ zum Beispiel eine „Rezeption“?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Ist Ihr Salon „Barrierefrei“ bzw. „Behinderten gerecht“?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin die Möglichkeit ihr Handy aufzuladen?",
    answers: [
      {
        text: "Ja",
        points: 2,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verwöhnen Sie Ihre Kundin mit ausgewählter angenehmer Musik zum Entspannen?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },

  {
    text: "Welche Größe hat in etwa Ihr Salon?",
    answers: [
      {
        text: "mehr als 10m²",
        points: 1,
      },
      {
        text: "mehr als 20m²",
        points: 2,
      },
      {
        text: "mehr als 30m²",
        points: 3,
      },
      {
        text: "mehr als 40m²",
        points: 4,
      },
      {
        text: "mehr als 50m²",
        points: 5,
      },
      {
        text: "mehr als 60m²",
        points: 6,
      },
      {
        text: "weiß nicht",
        points: 0,
      },
    ],
  },
  {
    text: "Welchen Boden hat Ihr Salon?",
    multi: true,
    answers: [
      {
        text: "Fliesenboden",
        points: 5,
      },
      {
        text: "Parkettboden",
        points: 4,
      },
      {
        text: "Teppichboden",
        points: 3,
      },
      {
        text: "PVC",
        points: 3,
      },
      {
        text: "Linoleum",
        points: 2,
      },
      {
        text: "Anderes Material",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin Internet mit Bildschirm an (WLAN, VPN oder Gleichwertiges)?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Befindet sich im Empfangsbereich eine Sitzgruppe oder Sitzgelegenheit mit Getränke Service?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Befindet sich im Eingangsbereich ein Schirmständer für Regenschirme?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Haben Sie in Ihrem Salon eine Klimaanlage?",
    answers: [
      {
        text: "Ja",
        points: 10,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Welche Systeme bieten sie an?",
    multi: true,
    answers: [
      {
        text: "Gel",
        points: 3,
      },
      {
        text: "Acryl",
        points: 5,
      },
      {
        text: "Fiberglas",
        points: 3,
      },
      {
        text: "Schablonentechnik",
        points: 5,
      },
      {
        text: "Naturnagel Verstärkung",
        points: 3,
      },
    ],
  },
  {
    text: "Wie viele Mitarbeiter haben Sie?",
    answers: [
      {
        text: "0",
        points: 0,
      },
      {
        text: "1",
        points: 1,
      },
      {
        text: "2",
        points: 2,
      },
      {
        text: "3",
        points: 3,
      },
      {
        text: "4",
        points: 4,
      },
    ],
  },
  {
    text: "Verwenden Sie einen elektronischen Terminkalender?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verwenden Sie diverse Fräsermaschinen?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Hat Ihr Arbeitstisch eine integrierte Staubabsaugung?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verfügt Ihr Salon über einen Kühlschrank?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
]

export const ServiceQuestions = [
  {
    text: "Bieten Sie Ihrer wartenden Kundin aktuelle Medienangebote (gedruckt oder digital) an?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie bargeldlose Zahlung an?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin einen „Servicevertrag“ zum Beispiel ein Abo System an?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin Ratenzahlung an?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Haben Sie mehrere Salons?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Wenn Sie mehrere Salons besitzen, wie viele sind das?",
    answers: [
      {
        text: "Mehr als 3 Salons",
        points: 3,
      },
      {
        text: "Mehr als 5 Salons",
        points: 5,
      },
      {
        text: "Mehr als 10 Salons",
        points: 10,
      },
      {
        text: "Mehr als 20 Salons",
        points: 20,
      },
      {
        text: "Ich habe nur einen Salon",
        points: 0,
      },
    ],
  },
  {
    text: "Wie viele Sprachen sprechen Sie?",
    answers: [
      {
        text: "Ich spreche eine Sprache",
        points: 1,
      },
      {
        text: "Ich spreche 2 Sprachen",
        points: 2,
      },
      {
        text: "Ich spreche mehre Sprachen",
        points: 3,
      },
    ],
  },
  {
    text: "Bieten Sie einen Nagelnotdienst an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie am Wochenende Behandlungen an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Behandlungen ab 20 Uhr an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie ein Kundenbindungssystem an? Zum Beispiel 3 Behandlungen erhalten aber nur 2 Behandlungen bezahlen?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin Getränke an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin kleine Snacks wie Chips, Nüsse oder Sandwiches an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Öffnungszeiten von Ihrem Salon?",
    answers: [
      {
        text: "Halbtägig",
        points: 3,
      },
      {
        text: "Ganztägig",
        points: 6,
      },
    ],
  },
  {
    text: "Haben Sie eine Visitenkarte?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin eine Garantie über die Haltbarkeit Ihrer Arbeit an?",
    answers: [
      {
        text: "Ja",
        points: 10,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Pediküre an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Maniküre und Handmassagen an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Maniküre und Handpflege für Männer an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Kinder Maniküre an?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Nail Art an?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
      {
        text: "Nur manchmal, wenn es die Kundin ausdrücklich wünscht",
        points: 2,
      },
    ],
  },
]

export const MediaQuestions = [
  {
    text: "Haben Sie eine Webseite mit Kundenbewertung?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Sind Sie in der regionalen Presse mit Inseraten präsent?",
    answers: [
      {
        text: "Ja",
        points: 4,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Sind Sie in Social Media vertreten?",
    multi: true,
    answers: [
      {
        text: "Facebook",
        points: 5,
      },
      {
        text: "Instagram",
        points: 5,
      },
      {
        text: "TikTok",
        points: 3,
      },
      {
        text: "Snapchat",
        points: 3,
      },
      {
        text: "Youtube",
        points: 5,
      },
      {
        text: "Linkedin",
        points: 1,
      },
      {
        text: "Twitter",
        points: 2,
      },
      {
        text: "Pinterest",
        points: 1,
      },
      {
        text: "WhatsApp",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Wo ist Ihr Salon stationiert?",
    answers: [
      {
        text: "Ebenerdig",
        points: 6,
      },
      {
        text: "Im Keller",
        points: 1,
      },
      {
        text: "An der Hauptstraße",
        points: 6,
      },
      {
        text: "Im 1. Stock",
        points: 3,
      },
      {
        text: "Im 2. Stock",
        points: 3,
      },
      {
        text: "Privathaus",
        points: 3,
      },
      {
        text: "Privatwohnung",
        points: 2,
      },
      {
        text: "In einem Shoppingcenter",
        points: 6,
      },
      {
        text: "Bei einem Friseur",
        points: 5,
      },
      {
        text: "In einem Kosmetiksalon",
        points: 5,
      },
      {
        text: "In einem Fitnessstudio",
        points: 5,
      },
    ],
  },
]

export const HygieneQuestions = [
  {
    text: "Sind die Tischoberflächen desinfizierbar?",
    answers: [
      {
        text: "Ja",
        points: 4,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verfügt Ihr Studio über eine Hygienestation?",
    answers: [
      {
        text: "Ja",
        points: 10,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Wenn wir die unterste Schublade Ihres Tisches öffnen, ist diese hygienisch einwandfrei?",
    answers: [
      {
        text: "Ja",
        points: 4,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Werden die Türklinken täglich mehrmals desinfiziert?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Manchmal",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Arbeiten Sie mit Mundschutz?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verwenden Sie bei Ihrer Arbeit Einweg-Handschuhe?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verwenden Sie benutzte Feilen bei mehreren Kunden?",
    answers: [
      {
        text: "Ja",
        points: 0,
      },
      {
        text: "Nein",
        points: 5,
      },
    ],
  },
  {
    text: "Gibt es für jede Kundin ihr „eigenes“ Feilenset?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Werden die verwendeten Instrumente täglich desinfiziert?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Wie oft reinigen Sie Ihren Salon?",
    answers: [
      {
        text: "Täglich",
        points: 3,
      },
      {
        text: "Täglich mehrmals",
        points: 6,
      },
      {
        text: "Jeden 2. Tag",
        points: 2,
      },
      {
        text: "1x in der Woche",
        points: 1,
      },
      {
        text: "Nach Bedarf",
        points: 0,
      },
    ],
  },
  {
    text: "Hat die Kundin die Möglichkeit, sich vor der Behandlung die Hände zu waschen?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Hat die Kundin die Möglichkeit, sich vor der Behandlung die Hände zu desinfizieren?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Verwenden Sie professionelle Arbeitskleidung?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
]

export const SanitaryQuestions = [
  {
    text: "Haben Sie im Sanitärbereich eine Damen- oder/und auch eine Herrentoilette?",
    multi: true,
    answers: [
      {
        text: "Damen",
        points: 4,
      },
      {
        text: "Herren",
        points: 6,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin warmes Wasser mit Waschbecken und Spiegel an?",
    answers: [
      {
        text: "Ja",
        points: 4,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie die Waschlotion in einem kontaktfreien Spender an?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin im Sanitärbereich einen beweglichen, beleuchteten Kosmetikspiegel an?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Hat die Kunde die Möglichkeit, ihre Handtasche auf einer großzügigen Ablagefläche abzustellen?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Werden WC Auflagen angeboten?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Gibt es auf der Toilette zusätzliche Sicherheitsgriffe?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin im Sanitärbereich Hygieneartikel wie Damenbinden oder Tampons an?",
    answers: [
      {
        text: "Ja",
        points: 3,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Welche Möglichkeit hat Ihre Kundin um sich nach dem waschen die Hände abzutrocknen?",
    multi: true,
    answers: [
      {
        text: "Papierhandtücher",
        points: 3,
      },
      {
        text: "Elektrischer Händetrockner",
        points: 5,
      },
      {
        text: "Baumwollhandtücher",
        points: 2,
      },
      {
        text: "nichts davon",
        points: 0,
      },
    ],
  },
  {
    text: "Bieten Sie Ihrer Kundin im Sanitärbereich die Hände-Desinfektion an?",
    answers: [
      {
        text: "Ja",
        points: 6,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
]

export const EducationQuestions = [
  {
    text: "Wie lange arbeiten Sie schon als Nageldesigner/in?",
    answers: [
      {
        text: "1 Jahr",
        points: 1,
      },
      {
        text: "2 Jahr",
        points: 2,
      },
      {
        text: "3 Jahr",
        points: 3,
      },
      {
        text: "4 Jahr",
        points: 4,
      },
      {
        text: "5 Jahr",
        points: 5,
      },
      {
        text: "6 Jahr",
        points: 6,
      },
      {
        text: "7 Jahr",
        points: 7,
      },
      {
        text: "8 Jahr",
        points: 8,
      },
      {
        text: "9 Jahr",
        points: 9,
      },
      {
        text: "10 Jahr",
        points: 10,
      },
      {
        text: "11 Jahr",
        points: 11,
      },
      {
        text: "12 Jahr",
        points: 12,
      },
      {
        text: "13 Jahr",
        points: 13,
      },
      {
        text: "14 Jahr",
        points: 14,
      },
      {
        text: "15 Jahr",
        points: 15,
      },
      {
        text: "Länger als 20 Jahre",
        points: 20,
      },
      {
        text: "Länger als 25 Jahre",
        points: 25,
      },
      {
        text: "Länger als 30 Jahre",
        points: 30,
      },
      {
        text: "Länger als 35 Jahre",
        points: 35,
      },
      {
        text: "Länger als 40 Jahre",
        points: 40,
      },
    ],
  },
  {
    text: "Bei wem und wo haben sie Ihre Ausbildung gemacht?",
    multi: true,
    answers: [
      {
        text: "An einer international anerkannten Ausbildungsstätte",
        points: 10,
      },
      {
        text: "Privat",
        points: 1,
      },
      {
        text: "Ich habe es mir selbst beigebracht",
        points: 0,
      },
    ],
  },
  {
    text: "Wie viele Ausbildungen haben Sie gemacht?",
    multi: true,
    answers: [
      {
        text: "Basiskurs",
        points: 3,
      },
      {
        text: "Fortbildungskurs",
        points: 4,
      },
      {
        text: "Master Class",
        points: 5,
      },
      {
        text: "Nail Art Kurs",
        points: 3,
      },
      {
        text: "Wettkampftraining",
        points: 5,
      },
      {
        text: "Mehr als 5 Ausbildungen",
        points: 5,
      },
      {
        text: "Mehr als 10 Ausbildungen",
        points: 10,
      },
      {
        text: "Mehr als 15 Ausbildungen",
        points: 15,
      },
    ],
  },
  {
    text: "Wie lange liegt Ihre letzte Fortbildung zurück?",
    answers: [
      {
        text: "Vor 1 Jahr",
        points: 8,
      },
      {
        text: "Vor 2 Jahren",
        points: 7,
      },
      {
        text: "Vor 3 Jahren",
        points: 6,
      },
      {
        text: "Vor 4 Jahren",
        points: 5,
      },
      {
        text: "Vor 5 Jahren",
        points: 4,
      },
      {
        text: "Vor 10 Jahren",
        points: 3,
      },
      {
        text: "Vor 15 Jahren",
        points: 2,
      },
      {
        text: "Schon länger als 15 Jahre",
        points: 1,
      },
    ],
  },
  {
    text: "Wie lange dauert im Durchschnitt eine Behandlung (Refill/Neues Set) in Ihrem Salon?",
    answers: [
      {
        text: "30 Minuten",
        points: 10,
      },
      {
        text: "60 Minuten",
        points: 5,
      },
      {
        text: "1,5 Stunden",
        points: 3,
      },
      {
        text: "2 Stunden",
        points: 2,
      },
      {
        text: "2,5 Stunden",
        points: 1,
      },
      {
        text: "Länger als 3 Stunden",
        points: 0,
      },
    ],
  },
  {
    text: "Nehmen Sie an Wettkämpfen teil?",
    multi: true,
    answers: [
      {
        text: "Weltmeisterschaften",
        points: 10,
      },
      {
        text: "Staatsmeisterschaften",
        points: 5,
      },
      {
        text: "Sonstige Meisterschaften",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Wie viele 1. Plätze haben Sie schon gewonnen?",
    answers: [
      {
        text: "Einen 1. Platz",
        points: 10,
      },
      {
        text: "Zwei 1. Plätze",
        points: 10,
      },
      {
        text: "Drei 1. Plätze",
        points: 10,
      },
      {
        text: "Mehr als fünf 1. Platz",
        points: 10,
      },
      {
        text: "Mehr als zehn 1. Platz",
        points: 10,
      },
      {
        text: "Mehr als fünfzehn 1. Platz",
        points: 10,
      },
      {
        text: "noch Keinen",
        points: 0,
      },
    ],
  },
  {
    text: "Haben Sie ein Triple A Zertifikat?",
    answers: [
      {
        text: "Ja",
        points: 20,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
]

export const SecurityQuestions = [
  {
    text: "Arbeiten sie mit Produkten, die REACH und CPNP notifiziert sind?",
    answers: [
      {
        text: "Ja",
        points: 20,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
]

export const MembershipQuestions = [
  {
    text: "Sind Sie Mitglied bei der World Nail Association (WNA)?",
    answers: [
      {
        text: "Ja",
        points: 20,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Sind Sie Mitglied bei der International Nail Association (INA)?",
    answers: [
      {
        text: "Ja",
        points: 10,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
  {
    text: "Sind Sie Mitglied bei der European Nail Association (ENA)?",
    answers: [
      {
        text: "Ja",
        points: 5,
      },
      {
        text: "Nein",
        points: 0,
      },
    ],
  },
]
