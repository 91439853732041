import React from "react"
import { Navigate } from "react-router-dom"
import Pages404 from "pages/Util/pages-404"
import Classifications from "pages/Classifications"
import Classification from "pages/Classification"

const authProtectedRoutes = [
  { path: "/klassifizierungen", component: <Classifications /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/klassifizierung" />,
  },
]

const publicRoutes = [
  { path: "/klassifizierung", component: <Classification /> },
  { path: "*", component: <Pages404 /> },
]

export { authProtectedRoutes, publicRoutes }
