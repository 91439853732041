import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

export const LoginUser = data => post(url.LOGIN, data)

export const RegisterUser = data => post(url.REGISTER, data)

export const ConfirmEmail = token => post(url.CONFIRM_EMAIL + "/" + token)

export const CreateUser = data => post(url.USERS, data)

export const UpdateUser = data => put(url.USERS, data)

export const ResetPassword = data => post(url.RESET_PASSWORD, data)

export const ChangePassword = (data, token) =>
  post(url.RESET_PASSWORD + "/" + token, data)

export const ChangeEmail = data => post(url.CHANGE_EMAIL, data)

export const ChangeEmailConfirmation = token =>
  post(url.CHANGE_EMAIL_CONFIRMATION + "/" + token)

export const DeleteUser = id => del(url.USERS + "/" + id)

export const GetMyUser = _ => get(url.MY_USER)

export const CreateClassification = data => post(url.CLASSIFICATIONS, data)

export const GetClassifications = _ => get(url.CLASSIFICATIONS)

export const AcceptClassifications = id => put(url.CLASSIFICATIONS + "/" + id)

export const DeleteClassifications = id => del(url.CLASSIFICATIONS + "/" + id)

export const CreateInvoice = reqObj => post(url.INVOICES, reqObj)
